<template>
  <CRMLayout>
    <template v-slot:header-back-btn>
      <router-link
        :to="{ name: 'ManageBuildSuspensionTravels' }"
        class="btn btn-white btn-reset add-models-btn"
      >
        Create new Suspension Travel
      </router-link>
    </template>

    <template v-slot:content>
      <div class="main__c">
        <div class="main__c-in">
          <div class="main__headline">
            <h5>Suspension Travels</h5>
          </div>

          <div class="scroll">
            <table v-if="hasTravels" class="table__typical table__orders">
              <tbody>
              <tr>
                <th>
                  <span>ID</span>
                </th>
                <th>
                  <span>Title</span>
                </th>
                <th>
                  <span>Active</span>
                </th>
                <th></th>
              </tr>

              <tr
                v-for="travel in suspensionTravels"
                :key="travel.id"
                @click="showTravel($event, travel)"
              >
                <td>{{ travel.id }}</td>
                <td>{{ travel.title }}</td>
                <td>{{ travel.active ? "Yes" : "No" }}</td>
                <td>
                  <div class="vacancy__item-visibility">
                    <button
                      :class="isHidden(travel)"
                      class="btn btn-show"
                      @click="switchActivity(travel)"
                    ></button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <paginator
            :current-page="currentPage"
            :last-page="lastPage"
            @changepage="changePage"
          />
        </div>
      </div>
    </template>
  </CRMLayout>
</template>

<script>
import CRMLayout from "./CRMLayout";
import Paginator from "./partials/Paginator";
import { mapGetters } from "vuex";

export default {
  name: "CRMBikeCategories",
  components: { CRMLayout, Paginator },
  data() {
    return {
      currentPage: 1,
      lastPage: 1,
    };
  },
  created() {
    this.loadTravels();
  },
  methods: {
    isHidden(travel) {
      return travel.active ? "" : "is-hidden";
    },
    switchActivity(travel) {
      let ctx = this;
      let data = {
        active: !travel.active,
        id: travel.id,
      };

      let requestName = "switch_suspension_travel_activity_request";

      this.$store
        .dispatch(requestName, data)
        .then(() => {
          travel.active = !travel.active;
        })
        .catch(err => {
          ctx.$root.$emit("modalShow", { type: "error", text: err });
        });
    },
    loadTravels() {
      let data = {
        page: this.currentPage,
      };
      this.$store.dispatch("bikes/loadTravels", data).then(result => {
        this.lastPage = result.data.meta["last_page"];
      });
    },
    changePage(page) {
      this.updateList = true;
      this.currentPage = page;
      this.loadTravels();
    },
    showTravel(event, travel) {
      if (!event.target.classList.contains("btn-show")) {
        this.$router.push({
          name: "ManageBuildSuspensionTravelsEdit",
          params: { id: travel.id },
        }).catch(() => {
        });
      }
    },
  },
  computed: {
    ...mapGetters({
      suspensionTravels: "bikes/suspensionTravels",
    }),
    hasTravels() {
      return this.suspensionTravels.length > 0;
    },
  },
};
</script>

<style scoped>
.table__orders {
  td:not(:first-child),
  th:not(:first-child) {
    width: auto;
  }

  td {
    cursor: pointer;
  }
}

@media screen and (max-width: 1300px) {

  .scroll table {
    min-width: 700px;
  }
}
</style>
