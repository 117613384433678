<template>
  <CRMLayout>
    <template v-slot:header-back-btn>
      <router-link
        :to="{ name: 'ManageBikeCategory' }"
        class="btn btn-white btn-reset add-models-btn"
      >
        Create new Bike Category
      </router-link>
    </template>

    <template v-slot:content>
      <div class="main__c">
        <div class="main__c-in">
          <div class="main__headline">
            <h5>Bike Categories</h5>
          </div>

          <div class="scroll">
            <table v-if="hasCategories" class="table__typical table__orders">
              <tbody>
              <tr>
                <th>
                  <span>ID</span>
                </th>
                <th>
                  <span>Title</span>
                </th>
                <th>
                  <span>Active</span>
                </th>
                <th></th>
              </tr>

              <tr
                v-for="category in categories"
                :key="category.id"
                @click="showCategory($event, category)"
              >
                <td>{{ category.id }}</td>
                <td>{{ category.title }}</td>
                <td>{{ category.active ? "Yes" : "No" }}</td>
                <td>
                  <div class="vacancy__item-visibility">
                    <button
                      :class="isHidden(category)"
                      class="btn btn-show"
                      @click="switchActivity(category)"
                    ></button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <paginator
            :current-page="currentPage"
            :last-page="lastPage"
            @changepage="changePage"
          />
        </div>
      </div>
    </template>
  </CRMLayout>
</template>

<script>
import CRMLayout from "./CRMLayout";
import Paginator from "./partials/Paginator";
import { mapGetters } from "vuex";

export default {
  name: "CRMBikeCategories",
  components: { CRMLayout, Paginator },
  data() {
    return {
      currentPage: 1,
      lastPage: 1,
    };
  },
  created() {
    this.loadCategories();
  },
  methods: {
    isHidden(category) {
      return category.active ? "" : "is-hidden";
    },
    switchActivity(category){
      let ctx = this;
      let data = {
        active: !category.active,
        id: category.id,
      };

      let requestName = "switch_filter_category_activity_request";

      this.$store
        .dispatch(requestName, data)
        .then(() => {
          category.active = !category.active;
        })
        .catch(err => {
          ctx.$root.$emit("modalShow", { type: "error", text: err });
        });
    },
    loadCategories() {
      let data = {
        page: this.currentPage,
      };
      this.$store.dispatch("bikes/loadCategories", data).then(result => {
        this.lastPage = result.data.meta["last_page"];
      });
    },
    changePage(page) {
      this.updateList = true;
      this.currentPage = page;
      this.loadCategories();
    },
    showCategory(event, category) {
      if (!event.target.classList.contains("btn-show")) {
        this.$router.push({
          name: "ManageBikeCategoryEdit",
          params: { id: category.id },
        }).catch(() => {
        });
      }
    },
  },
  computed: {
    ...mapGetters({
      categories: "bikes/categories",
    }),
    hasCategories() {
      return this.categories.length > 0;
    },
  },
};
</script>

<style scoped>
.table__orders{
  td:not(:first-child),
  th:not(:first-child) {
    width: auto;
  }
  td{
    cursor: pointer;
  }
}
@media screen and (max-width: 1300px) {

  .scroll table {
    min-width: 700px;
  }
}
</style>
