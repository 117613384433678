<template>
  <CRMLayout>
    <template v-slot:header-back-btn>
      <router-link
        :to="{ name: 'ManageBikeMotorType' }"
        class="btn btn-white btn-reset add-models-btn"
      >
        Create new Build Motor Type
      </router-link>
    </template>

    <template v-slot:content>
      <div class="main__c">
        <div class="main__c-in">
          <div class="main__headline">
            <h5>Build Motor Types</h5>
          </div>

          <div class="scroll">
            <table v-if="hasTypes" class="table__typical table__orders">
              <tbody>
              <tr>
                <th>
                  <span>ID</span>
                </th>
                <th>
                  <span>Title</span>
                </th>
                <th>
                  <span>Active</span>
                </th>
                <th></th>
              </tr>

              <tr
                v-for="type in motorTypes"
                :key="type.id"
                @click="showType($event, type)"
              >
                <td>{{ type.id }}</td>
                <td>{{ type.title }}</td>
                <td>{{ type.active ? "Yes" : "No" }}</td>
                <td>
                  <div class="vacancy__item-visibility">
                    <button
                      :class="isHidden(type)"
                      class="btn btn-show"
                      @click="switchActivity(type)"
                    ></button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <paginator
            :current-page="currentPage"
            :last-page="lastPage"
            @changepage="changePage"
          />
        </div>
      </div>
    </template>
  </CRMLayout>
</template>

<script>
import CRMLayout from "./CRMLayout";
import Paginator from "./partials/Paginator";
import { mapGetters } from "vuex";

export default {
  name: "CRMBuildMotorTypes",
  components: { CRMLayout, Paginator },
  data() {
    return {
      currentPage: 1,
      lastPage: 1,
    };
  },
  created() {
    this.loadMotorTypes();
  },
  methods: {
    isHidden(type) {
      return type.active ? "" : "is-hidden";
    },
    switchActivity(type) {
      let ctx = this;
      let data = {
        active: !type.active,
        id: type.id,
      };

      let requestName = "switch_motor_type_activity_request";

      this.$store
        .dispatch(requestName, data)
        .then(() => {
          type.active = !type.active;
        })
        .catch(err => {
          ctx.$root.$emit("modalShow", { type: "error", text: err });
        });
    },
    loadMotorTypes() {
      let data = {
        page: this.currentPage,
      };
      this.$store.dispatch("bikes/loadMotorTypes", data).then(result => {
        this.lastPage = result.data.meta["last_page"];
      });
    },
    changePage(page) {
      this.updateList = true;
      this.currentPage = page;
      this.loadMotorTypes();
    },
    showType(event, type) {
      if (!event.target.classList.contains("btn-show")) {
        this.$router.push({
          name: "ManageBikeMotorTypeEdit",
          params: { id: type.id },
        }).catch(() => {
        });
      }
    },
  },
  computed: {
    ...mapGetters({
      motorTypes: "bikes/motorTypes",
    }),
    hasTypes() {
      return this.motorTypes.length > 0;
    },
  },
};
</script>

<style scoped>
.table__orders {
  td:not(:first-child),
  th:not(:first-child) {
    width: auto;
  }

  td {
    cursor: pointer;
  }
}

@media screen and (max-width: 1300px) {

  .scroll table {
    min-width: 700px;
  }
}
</style>
