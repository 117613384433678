<template>
  <CRMLayout>
    <template v-slot:header-title>
      <span>{{ !createMode ? "Updating" : "Creating" }} Build Suspension Travel</span>
    </template>

    <template v-slot:content>
      <div class="main__c main__c-auto">
        <div class="main__c-in">
          <div class="main__headline">
            <h5>Main info</h5>
          </div>

          <div class="main__content">
            <div class="main__content-in">
              <f-input-text
                v-model="travel.title"
                :disabled="loading"
                title="Title"
              />

              <f-input-checkbox v-model="travel.active" title="Activity" />
            </div>
          </div>

          <div class="main__content-btns">
            <button class="btn btn-big btn-fw" @click="save">
              <span><i>{{ createMode ? "Save" : "Update" }} Suspension Travel</i></span>
            </button>
          </div>
        </div>
      </div>
    </template>
  </CRMLayout>
</template>

<script>
import CRMLayout from "./CRMLayout";
import Text from "@/components/inputs/Text";
import CheckBox from "@/components/inputs/CheckBox";

export default {
  name: "ManageBuildSuspensionTravels",
  components: {
    CRMLayout,
    "f-input-text": Text,
    "f-input-checkbox": CheckBox,
  },
  data() {
    return {
      nonce: 1,
      travel: {
        id: null,
        title: null,
        active: false,
      },
      loading: true,
    };
  },
  computed: {
    createMode() {
      return !this.$route.params.id;
    },
  },
  mounted() {
    if (this.createMode) {
      this.loading = false;
    }
    this.loadTravel();
  },
  methods: {
    loadTravel() {
      if (!this.createMode) {
        this.$store
          .dispatch("bikes/loadSuspensionTravelsSingle", this.$route.params.id)
          .then(result => {
            if (result) {
              for (let key of Object.keys(result)) {
                // eslint-disable-next-line
                if (this.travel.hasOwnProperty(key)) {
                  this.travel[key] = result[key];
                }
              }
            }
            this.loading = false;
          })
          .catch(() => {
            this.$root.$emit("modalShow", {
              text: "Something went wrong. Try later",
              type: "error",
            });
          });
      }
    },
    save() {
      this.$store
        .dispatch("bikes/manageSuspensionTravel", this.travel)
        .then(result => {
          if (this.createMode) {
            this.$router.push("/suspension-travels/" + result.id);
          }

          this.$root.$emit("modalShow", {
            text: `Suspension Travel ${this.createMode ? "created" : "updated"}`,
            type: "info",
          });
        })
        .catch(err => {
          this.$root.$emit("modalShow", { text: err, type: "error" });
        });
    },
  },
};
</script>
