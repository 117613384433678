<template>
  <CRMLayout>
    <template v-slot:header-back-btn>
      <router-link
        :to="{ name: 'ManageBikeExperience' }"
        class="btn btn-white btn-reset add-models-btn"
      >
        Create new Build Experience
      </router-link>
    </template>

    <template v-slot:content>
      <div class="main__c">
        <div class="main__c-in">
          <div class="main__headline">
            <h5>Build Experiences</h5>
          </div>

          <div class="scroll">
            <table v-if="hasExperiences" class="table__typical table__orders">
              <tbody>
              <tr>
                <th>
                  <span>ID</span>
                </th>
                <th>
                  <span>Title</span>
                </th>
                <th>
                  <span>Active</span>
                </th>
                <th></th>
              </tr>

              <tr
                v-for="experience in experiences"
                :key="experience.id"
                @click="showExperience($event, experience)"
              >
                <td>{{ experience.id }}</td>
                <td>{{ experience.title }}</td>
                <td>{{ experience.active ? "Yes" : "No" }}</td>
                <td>
                  <div class="vacancy__item-visibility">
                    <button
                      :class="isHidden(experience)"
                      class="btn btn-show"
                      @click="switchActivity(experience)"
                    ></button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <paginator
            :current-page="currentPage"
            :last-page="lastPage"
            @changepage="changePage"
          />
        </div>
      </div>
    </template>
  </CRMLayout>
</template>

<script>
import CRMLayout from "./CRMLayout";
import Paginator from "./partials/Paginator";
import { mapGetters } from "vuex";

export default {
  name: "CRMBuildExperiences",
  components: { CRMLayout, Paginator },
  data() {
    return {
      currentPage: 1,
      lastPage: 1,
    };
  },
  created() {
    this.loadExperiences();
  },
  methods: {
    isHidden(experience) {
      return experience.active ? "" : "is-hidden";
    },
    switchActivity(experience) {
      let ctx = this;
      let data = {
        active: !experience.active,
        id: experience.id,
      };

      let requestName = "switch_experience_activity_request";

      this.$store
        .dispatch(requestName, data)
        .then(() => {
          experience.active = !experience.active;
        })
        .catch(err => {
          ctx.$root.$emit("modalShow", { type: "error", text: err });
        });
    },
    loadExperiences() {
      let data = {
        page: this.currentPage,
      };
      this.$store.dispatch("bikes/loadExperiences", data).then(result => {
        this.lastPage = result.data.meta["last_page"];
      });
    },
    changePage(page) {
      this.updateList = true;
      this.currentPage = page;
      this.loadExperiences();
    },
    showExperience(event, experience) {
      if (!event.target.classList.contains("btn-show")) {
        this.$router.push({
          name: "ManageBikeExperienceEdit",
          params: { id: experience.id },
        }).catch(() => {
        });
      }
    },
  },
  computed: {
    ...mapGetters({
      experiences: "bikes/experiences",
    }),
    hasExperiences() {
      return this.experiences.length > 0;
    },
  },
};
</script>

<style scoped>
.table__orders {
  td:not(:first-child),
  th:not(:first-child) {
    width: auto;
  }

  td {
    cursor: pointer;
  }
}

@media screen and (max-width: 1300px) {

  .scroll table {
    min-width: 700px;
  }
}
</style>
